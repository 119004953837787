import Container from "../components/container/Container";
import { useTranslation } from "react-i18next";
import "../../css/components/team-leads/team-leads.scss";

const TeamLeads = () => {
    const {t: __} = useTranslation();  

    return (
        <div className="team-leads">
            <Container>
                <div className="team-leads-description">
                    <div className="team-leads-hero">
                        <img src="/img/team-leads/fizmat-tnpu-logo.svg" alt="hero"/>
                    </div>
                    <div className="team-leads-topic">
                        <h2 className="team-leads-header">
                            {__("Faculty of Physics and Mathematics")}
                        </h2>
                        <p className="team-leads-info">
                            {__("Over the period of its development, the faculty has trained more than 10,000 qualified teachers of mathematics, physics and computer science, including three head teachers. oblast (A.Y. Romanyuk, S. I. Tsyganyuk, I.G. Zaporozhan), more than 20 heads district, directors of stations of young technicians, directors of schools, excellent students of public education, Methodist teachers. More than 100 graduates of the faculty defended their dissertations and became candidates or doctors of science, associate professors and professors and today are teachers of the faculty and the university.")}
                        </p>
                    </div>          
                </div>
            </Container>
            <Container>
                <h2 className="team-leads-header">
                    {__("Team Leads")}
                </h2>
                <div className="team-leads-gallery">
                    <div className="team-leads-banner">
                        <img className="team-leads-banner-img" src="./img/team-leads/Oleksyuk1.jpg" alt="img"/>
                    </div>
                    <div className="team-leads-top">
                        <h3 className="team-leads-top-header">
                            {__("Oleksyuk Vasyl Petrovych")}
                        </h3>
                        <h4 className="team-leads-top-subheader">
                            {__("Doctor of Pedagogical Sciences")}
                        </h4>
                        <p>
                            {__("Candidate of Pedagogical Sciences, majoring in 'Theory and teaching methods. Computer Science'. He defended his PhD thesis in 2007.")}
                        </p>
                        <p>
                            {__("The author of more than 50 scientific publications, 34 of them in specialized publications of the Ministry of Education and Culture of Ukraine, 6 - in publications indexed by the Web of Science and Scopus scientific databases, 1 - a manual for universities with the Ministry of Education and Culture seal.")}
                        </p>
                    </div>
                </div>
                <div className="team-leads-gallery">
                    <div className="team-leads-top">
                        <h3 className="team-leads-top-header">
                            {__("Dmytro Verbovetskyi")}
                        </h3>
                        <p>
                            {__("The idea behind the project. Tim is the leader of the group, the generator of the content line of the project and individual tasks.")}
                        </p>
                    </div>
                    <div className="team-leads-banner">
                        <img className="team-leads-banner-img" src="./img/team-leads/dima.jpg" alt="img"/>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default TeamLeads;