import { createBrowserRouter } from "react-router-dom";
import HomePage from "../view/pages/HomePage";
import AboutGame from "../view/pages/AboutGame";
import AboutUs from "../view/pages/AboutUs";
import TeamLeags from "../view/pages/TeamLeags";
import Layout from "../view/components/layout/Layout";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        children: [
            {
                path: '/',
                element: <HomePage />
            },
            {
                path: '/about-game',
                element: <AboutGame />
            },
            {
                path: '/about-us',
                element: <AboutUs />
            },
            {
                path: '/team-leads',
                element: <TeamLeags />
            },
        ]
    },
    {
        path: '*',
        element: <div className="page-404"> 404... </div>
    }
]);

export default router;