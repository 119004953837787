import Container from "../components/container/Container";
import { useTranslation } from "react-i18next";
import "../../css/components/about-us/about-us.scss";

const AboutUs = () => {
    const {t: __} = useTranslation();  

    return (
        <div className="about-us">
            <Container>
                <div className="about-us-description">
                    <div className="about-us-img">
                        <img src="/img/about-us/kn-n.jpg" alt="hero"/>
                    </div>
                    <div className="about-us-topic">
                        <h2 className="about-us-header">
                            {__("Our team")}
                        </h2>
                        <p className="about-us-info">
                            {__("Join our dynamic student group where creativity meets camaraderie! Together, we craft captivating video games fueled by passion and collaboration. With a supportive environment and diverse talents, we're turning dreams into digital realities. Come be a part of our powerful and friendly collective, where every voice is heard and every idea is valued.")}
                        </p>
                    </div>          
                </div>
            </Container>
            <Container>
                <h2 className="about-us-header">
                    {__("Core Mates")}
                </h2>
                <div className="about-us-gallery">
                    <div className="about-us-banner">
                        <img className="about-us-banner-img" src="./img/about-us/petro.jpg" alt="petro"/>
                    </div>
                    <div className="about-us-top">
                        <h3 className="about-us-top-header">
                            {__("Petro Melnuk")}
                        </h3>
                        <p>
                            {__("The driving force behind our team's flagship project, our local captain combines visionary leadership with meticulous design skills, steering us toward success in our ambitious endeavors.")}
                        </p>
                    </div>
                </div>
                <div className="about-us-gallery">
                    <div className="about-us-top">
                        <h3 className="about-us-top-header">
                            {__("Denus Dzhuga")}
                        </h3>
                        <p>
                            {__("Our main programmer is the coding maestro who brings our projects to life, while also adding a touch of charm with their cool and cute animations.")}
                        </p>
                    </div>
                    <div className="about-us-banner rigth">
                        <img className="about-us-banner-img" src="./img/about-us/denus.jpg" alt="denus"/>
                    </div>
                </div>
                <div className="about-us-gallery">
                    <div className="about-us-banner">
                        <img className="about-us-banner-img" src="./img/about-us/vlad.png" alt="vlad"/>
                    </div>
                    <div className="about-us-top">
                        <h3 className="about-us-top-header">
                            {__("Vladuslav Serpevsky")}
                        </h3>
                        <p>                        
                            {__("Our team's talisman, hailing from the quaint village of Borschivka, infuses our work with laughter and levity, spinning good jokes about everything under the sun.")}
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default AboutUs;