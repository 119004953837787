import "../../../css/components/header/header.scss";
import { Container } from "../container";
import { Link } from "react-router-dom";
import React, {useState, useCallback, useEffect} from "react";
import { useTranslation } from 'react-i18next'

const LOCALES = [
    {
        label: "UA",
        value: "uk-UA"
    },
    {
        label: "EN",
        value: "en"
    }
];

const Header = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { i18n, t: __ } = useTranslation()

    const getLocales = () => {
        const isLocaleExist = LOCALES.some(({value}) => value === localStorage?.i18nextLng);

        if (isLocaleExist) {
            return localStorage?.i18nextLng;
        }

        localStorage.i18nextLng = "en";
        return "en";
    };

    const selectedLocales = getLocales();

    const onClick = useCallback(() => {
        setIsExpanded(true)
    }, []);

    const onClose = useCallback(() => {
        setIsExpanded(false)
    }, []);

    const onLocale = useCallback(e => {
        const value = e.target.value;

        i18n.changeLanguage(value);
    }, []);

    return (
        <Container>
            <header className="header">
                <nav className="header-nav header-nav-mobile">
                    <div className="header-progress-top" />
                    <div className="header-progress-bottom" />
                    <ul className="header-section">
                        <li className="header-item">
                            <Link to="/" className="header-link">
                                <img src="/img/small-logo.png" className="header-logo" alt="logo"/>
                            </Link>
                        </li>
                    </ul>
                    <ul className="header-section">
                        <li className="header-item item-animated">
                            <button className="header-button" onClick={onClick}>
                                <svg fill="#ffffff" width="40px" height="40px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <title>bars-sort</title>
                                    <path d="M30 7.249h-28c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h28c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM18 15.25h-16c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h16c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM8.053 23.25h-6.053c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h6.053c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
                                </svg>
                            </button>
                        </li>
                    </ul>
                </nav>
                <nav className="header-nav header-nav-desktop">
                    <div className="header-progress-top" />
                    <div className="header-progress-bottom" />
                    <ul className="header-section divider">
                        <li className="header-item">
                            <Link to="/" className="header-link">
                                <span className="">
                                    <img src="/img/small-logo.png" className="header-logo" alt="logo"/>
                                </span>
                            </Link>
                        </li>
                    </ul>
                    <ul className="header-section">
                        <li className="header-item item-animated">
                            <Link to="/about-game" className="header-link">
                                <span className="header-link-label">
                                    {__("About Game")}
                                </span>
                            </Link>
                        </li>
                        <li className="header-item item-animated">
                            <Link to="/about-us" className="header-link">
                                <span className="header-link-label">
                                    {__("About Us")}
                                </span>
                            </Link>
                        </li>
                        <li className="header-item item-animated">
                            <Link to="/team-leads" className="header-link">
                                <span className="header-link-label">
                                    {__("Team Leads")}
                                </span>
                            </Link>
                        </li>
                    </ul>
                    <ul className="header-section">
                        <li className="header-item locale-wrapper">
                            <select className="locale" onChange={onLocale}>
                                {
                                    LOCALES.map(({label, value}) => {
                                        const isSelected = selectedLocales === value;
                                            debugger
                                        return (
                                            <option key={value} value={value} selected={isSelected}>
                                                {label}
                                            </option>
                                        )
                                    })    
                                }
                            </select>
                            <Link to="https://github.com/PetroMelnykW/StudentSimulator/releases" className="primary-button">
                                <span className="primary-button-label">
                                    <span className="header-link-label">
                                        {__("Download")}
                                    </span>
                                </span>
                            </Link>
                        </li>
                    </ul>
                </nav>
                <nav className={isExpanded ? "nav-active" : "nav-burger"}>
                    <div className="nav-content">
                        <h3 className="nav-title">
                            {__("Menu")}
                        </h3>
                        <button className="nav-close" onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="-0.5 0 25 25" fill="none">
                                <path d="M3 21.32L21 3.32001" stroke="#000000"/>
                                <path d="M3 3.32001L21 21.32" stroke="#000000"/>
                            </svg>
                        </button>
                        <ul className="nav-list">
                            <li className="nav-item">
                                <Link onClick={onClose} to="/" className="header-link">
                                    {__("Home")}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link onClick={onClose} to="/about-game" className="header-link">
                                    {__("About Game")}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link onClick={onClose} to="/about-us" className="header-link">
                                    {__("About Us")}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link onClick={onClose} to="/team-leads">
                                    {__("Team Leads")}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link onClick={onClose} to="https://github.com/PetroMelnykW/StudentSimulator/releases">
                                    {__("Download")}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <select className="locale" onChange={onLocale}>
                                    {
                                        LOCALES.map(({label, value}) => {
                                            const isSelected = selectedLocales === value;
                                            return (
                                                <option key={value} value={value} defaultValue={isSelected}>
                                                    {label}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </Container>
    );
}

export default Header;