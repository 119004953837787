import Container from "../components/container/Container";
import { useTranslation } from "react-i18next";
import "../../css/components/about-game/about-game.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AboutGame = () => {
    const {t: __} = useTranslation();  

    const settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                dots: false,
              }
            }
        ]
    };

    return (
        <div className="about-game">
            <div className="about-game-hero">
                <Container>
                    <Slider {...settings}>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_1.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_2.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_3.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_4.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_5.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_6.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_7.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_8.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_9.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_10.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_11.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_12.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_13.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_14.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_15.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_16.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_17.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_18.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_19.JPG" alt="game-slide"/>
                        </div>
                    </Slider>
                </Container>
            </div>
            <div className="about-game-description">
                <Container>
                    <div className="about-game-topic">
                        <h2 className="about-game-header">
                            {__("Our Mission")}
                        </h2>
                        <p className="about-game-info">
                            {__("Your primary objective in 'Student Simulator' is to excel academically and achieve success in your chosen field of study. To accomplish this, you must navigate through various challenges and opportunities presented throughout your college journey. From freshman year to graduation, you'll encounter obstacles such as tough exams, demanding assignments, and competitive classmates. Your mission is to rise above these challenges, maintain high grades, and ultimately graduate with honors.")}
                        </p>
                        <div className="about-game-img">
                            <img src="/img/sliders/video-game-assets_2.JPG" alt="game-slide"/>
                        </div>
                        <p className="about-game-info">
                            {__("As you progress through your college journey and accomplish various milestones, you'll unlock new opportunities, face tougher challenges, and ultimately prove yourself as a top student in 'Student Simulator.' Are you ready to embark on this academic adventure and rise to the top of the class?")}
                        </p>
                        <div className="about-game-img">
                            <img src="/img/sliders/video-game-assets_5.JPG" alt="game-slide"/>
                        </div>
                    </div>        
                    <div className="about-game-topic">
                        <h2 className="about-game-header">
                            {__("Engine")}
                        </h2>
                        <p className="about-game-info">
                            {__("Our videogame is based on amazing videogame engine - Godot. Godot is a powerful and versatile open-source game engine that empowers developers to create a wide range of 2D and 3D games, interactive experiences, and simulations. With its intuitive interface, robust feature set, and active community support, Godot has become a popular choice for both indie developers and established studios alike.")}
                        </p>
                        <div className="about-game-img">
                            <img src="/img/sliders/video-game-assets_12.JPG" alt="game-slide"/>
                        </div>
                        <p className="about-game-info">
                            {__("2D and 3D Rendering: Godot provides powerful rendering capabilities for both 2D and 3D graphics, including support for shaders, lighting, physics, particle systems, and more. Whether you're creating a retro-style platformer or a cutting-edge 3D adventure, Godot offers the tools you need to bring your vision to life.")}
                        </p>
                        <div className="about-game-img">
                            <img src="/img/sliders/video-game-assets_11.JPG" alt="game-slide"/>
                        </div>
                    </div>        
                    <div className="about-game-topic">
                        <h2 className="about-game-header">
                            {__("Education")}
                        </h2>
                        <p className="about-game-info">
                            {__("'Student Simulator' is an immersive educational video game that combines quizzes with hands-on programming exercises, inspired by the simplicity of Scratch and the depth of university-level course material. In this game, players embark on a journey through various academic subjects, tackling quizzes and building programs to demonstrate their understanding.")}
                        </p>
                        <div className="about-game-img">
                            <img src="/img/sliders/video-game-assets_13.JPG" alt="game-slide"/>
                        </div>
                        <p className="about-game-info">
                            {__("'Student Simulator' provides a dynamic and engaging learning experience that combines the rigor of university-level education with the fun and interactivity of gaming. By blending quizzes with hands-on programming challenges, the game empowers students to become active participants in their own education, mastering complex concepts while having fun along the way.")}
                        </p>
                        <div className="about-game-img">
                            <img src="/img/sliders/video-game-assets_9.JPG" alt="game-slide"/>
                        </div>
                    </div>          
                </Container>
            </div>
        </div>
    );
}

export default AboutGame;