import React from "react";
import "../../../css/components/footer/footer.scss";
import { useTranslation } from "react-i18next";
import Container from "../../components/container/Container";
import { Link } from "react-router-dom";

const Footer = () => {
    const {t: __} = useTranslation();  

    return (
        <footer className="footer">
            <Container>
                <div className="footer-download">
                    <div className="footer-download-heading">
                        <div className="footer-download-info">
                            <h3 className="footer-download-header">
                                {__("Are you ready?")}
                            </h3>
                            <p className="footer-download-topic">
                                {__("Student Simulator: Dive into University Life and Experience the Ultimate Adventure!")}
                            </p>
                            <p className="footer-download-topic">
                                {__("You can download our videogame by GitHub from Microsoft.")}
                            </p>
                            <div className="footer-download-banner">
                                <div className="footer-download-banner-content">
                                    <a href="https://github.com/PetroMelnykW/StudentSimulator/releases/">
                                        <img className="footer-download-img" src="./img/github.png" alt="git-hub" />
                                    </a>
                                </div>
                                <div className="footer-download-banner-actions">
                                    <a href="https://github.com/PetroMelnykW/StudentSimulator/releases" className="primary-button">
                                        <span className="primary-button-label">
                                            <span className="header-link-label">
                                                {__("Download")}
                                            </span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <video src="./video/bg/bg-banner.mp4" className="footer-download-bg" autoPlay={true} loop={true} muted={true} playsInline={true} loading="lazy" />
                </div>
            </Container>
            <Container>
                <div className="footer-seperator" />
                <div className="footer-content">
                    <div className="footer-credentials">
                        <h3 className="footer-header">credentials</h3>  
                        <ul className="footer-credentials-list">
                            <li className="footer-credentials-item">
                                <address className="footer-credentials-content">
                                    <span className="footer-credentials-label">    
                                        {__("Address")}
                                    </span>    
                                    <span className="footer-credentials-data">    
                                        {__("Ternopil Volodymyr Hnatiuk National Pedagogical University")}
                                    </span>
                                    <span className="footer-credentials-data">    
                                        {__("Ukraine, Ternopil")}
                                    </span>
                                    <span className="footer-credentials-data">    
                                        {__("46027, Ternopil, str. Maxim Kryvonos, 2")}
                                    </span>    
                                </address>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-navigation">
                        <h3 className="footer-header">navigation</h3>  
                        <ul className="footer-navigation-list">
                            <li className="footer-navigation-item">
                                <Link to="/about-game" className="footer-navigation-link">
                                    {__("About Game")}
                                </Link>
                            </li>
                            <li className="footer-navigation-item">
                                <Link to="/about-us" className="footer-navigation-link">
                                    {__("About Us")}
                                </Link>
                            </li>
                            <li className="footer-navigation-item">
                                <Link to="/team-leads" className="footer-navigation-link">
                                    {__("Team Leads")}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-contacts">
                        <h3 className="footer-header">contacts</h3>  
                        <ul className="footer-address-list">
                            <li className="footer-address-item">
                                <address className="footer-address-content">
                                    <span className="footer-address-label">    
                                        {__("Drop Us A Line")}
                                    </span>    
                                    <span className="footer-address-data">    
                                        <a href="email:info@tnpu.edu.ua">
                                            info@tnpu.edu.ua
                                        </a>
                                    </span>    
                                </address>
                            </li>
                            <li className="footer-address-item">
                                <address className="footer-address-content">
                                    <span className="footer-address-label">    
                                        {__("Call Us")} 
                                    </span>
                                    <span className="footer-address-data">  
                                        <a href="tel:(098) 416-65-93">
                                            (098) 416-65-93
                                        </a>  
                                    </span>
                                </address>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-seperator" />
            </Container>
        </footer>
    );
}

export default Footer;