import "../../../css/components/container/container.scss";

const Container = ({children}) => {
    return (
        <div className="container">
            {children}
        </div>
    );
}

export default Container;