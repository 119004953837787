import { RouterProvider } from "react-router-dom";
import router from "./router.config";
import '../css/theme.scss';

const App = () => {
    return (
        <RouterProvider router={router} />
    );
}

export default App;