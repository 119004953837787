import { Outlet } from "react-router";
import Header from '../header/Header';
import Footer from '../footer';

const Layout = () => {
    return (
        <div>
            <Header/>
            <main className="main-column grid-component">
                <Outlet/>
            </main>
            <Footer />
        </div>
    );
}

export default Layout;