import Container from "../components/container/Container";
import { useTranslation } from "react-i18next";
import "../../css/components/hero/hero.scss";
import "../../css/components/homepage/homepage.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";


const DefaultPage = () => {
    const {t: __} = useTranslation();  

    const settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                dots: false,
              }
            }
        ]
    };

    return (
        <div className="homepage">
            <div className="homepage-aurora"/>
            <div className="hero">
                <Container>
                    <div className="hero-banner">
                        <div className="hero-banner-main">
                            <img className="banner" src="/img/banner.jpg" alt="banner"/>
                        </div>
                        <div className="hero-banner-additional">
                            <a href="https://tnpu.edu.ua/">
                                <img className="hero-banner-additional-img" src="./img/tnpu_logo.png" alt="banner"/>
                                <video src="./video/bg/bg.mp4" className="hero-banner-additional-bg" autoPlay={true} loop={true} muted={true} playsInline={true} loading="lazy" />
                            </a>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="game">
                <Container>
                    <div className="game-banner-main">
                        <img className="game-banner-img" src="/img/game-bg.jpg" alt="game-banner"/>
                    </div>
                    <div className="game-description">
                        <h1 className="game-header">{__("Student Simulator")}</h1>
                        <p className="game-description-topic">
                            {__("Welcome to Student Simulator, the groundbreaking simulation videgame that blurs the lines between studying and playing! In Student Simulator, players assume the role of a university student embarking on a thrilling academic journey while experiencing the vibrant university life!")}
                        </p>
                    </div>
                    <Slider {...settings}>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_1.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_17.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_4.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_7.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_2.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_15.JPG" alt="game-slide"/>
                        </div>
                        <div className="game-slide">
                            <img src="/img/sliders/video-game-assets_19.JPG" alt="game-slide"/>
                        </div>
                    </Slider>
                </Container>
                <div className="view-more-wrapper">
                    <Link to="/about-game" className="view-more">
                        {__("View More")}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default DefaultPage;